import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectKits, 
    setKits, 
    selectProducts, 
    setProducts,
    selectHasWholesale, 
    setHasWholesale, 
    selectTotalAmountRetail, 
    setTotalBreakdown, 
    setTotalAmount, 
    setTotalAmountWholesale, 
    setTotalAmountRetail,
    selectTotalBreakdown
} from '../../../hooks/slices/counterSlice';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Flex,
    Select,
    Spinner,
    useTheme,
    useMediaQuery
} from '@chakra-ui/react';
import { capitalizeFirstLetter } from '../../../resource/validate';

import { toast } from 'react-toastify';

const ModalSelectColor = ({ isOpen, onClose, kit, showKitIncludes, setShowKitIncludes, values }) => {
    const { breakpoints } = useTheme();

    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const kitsStore = useSelector(selectKits);
    const productsStore = useSelector(selectProducts);
    const hasWholesale = useSelector(selectHasWholesale);
    const totalAmount = useSelector(selectTotalAmountRetail);
    const totalBreakdown = useSelector(selectTotalBreakdown);
    
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [colors1, setColors1] = useState([]);
    const [colors2, setColors2] = useState([]);
    const [colors3, setColors3] = useState([]);
    const [colors4, setColors4] = useState([]);
    const [valuesTypeColor, setValuesTypeColor] = useState({
        colorp1: "",
        colorp2: "",
        colorp3: "",
        colorp4: ""
    });

    const validateValues = () => {
        if (valuesTypeColor.colorp1 !== "" && valuesTypeColor.colorp2 !== "" && valuesTypeColor.colorp3 !== "" && valuesTypeColor.colorp4 !== "") {
            return false;
        }
        return true;
    }

    const handleChangeSelected = (e) => {
        setValuesTypeColor({
            ...valuesTypeColor,
            [e.target.name]: e.target.value
        })
    }

    const addKitShoppingCart = (newListOptionsColorKit) => {
        setLoading(true);
        if (newListOptionsColorKit.length > 0) {
            let sumTotalKitFinalWholesale = 0;
            let sumTotalKitFinalRetail = 0;
            newListOptionsColorKit.forEach((item) => {
                sumTotalKitFinalWholesale += item.wholesale_price
                sumTotalKitFinalRetail += item.retail_price
            })
            //sumTotal = sumTotal;
            const idx = handleGetKitIdx(newListOptionsColorKit);
            let kits = [...kitsStore];
            if(idx === -1){
                let sumTotalRetail = sumTotalKitFinalRetail * values.num;
                let sumTotalKitFinal = hasWholesale || totalAmount >= 15001 || (totalAmount + sumTotalRetail > 15000) ? sumTotalKitFinalWholesale : sumTotalKitFinalRetail;
                let sumTotal = sumTotalKitFinal * values.num;
                let discountSumTotal = sumTotal * 0.05;
                let unitary_prirce = sumTotal / values.num;
                //discountSumTotal = discountSumTotal * values.num;
                const kitAdd = {
                    is_kit: true,
                    sku_kit: kit?.sku,
                    code_kit: kit?.code,
                    name_kit: kit?.name,
                    sub_sum_total_kit: sumTotal.toFixed(2),
                    sum_total_kit: sumTotal.toFixed(2),
                    sum_total: sumTotal.toFixed(2),
                    sum_discount_kit: sumTotal - discountSumTotal,
                    unitary_retail_price: sumTotalKitFinalRetail,
                    unitary_wholesale_price: sumTotalKitFinalWholesale,
                    unitary_kit_price: unitary_prirce,
                    discount_kit: discountSumTotal.toFixed(2),
                    total_kits: values.num,
                    items: newListOptionsColorKit
                }
                kits.push(kitAdd)
            }else{
                let kit_ = {...kitsStore[idx]};
                let sumTotalRetail = sumTotalKitFinalRetail * (values.num + kit_.total_kits);
                //let sumTotalWholesale = sumTotalKitFinalWholesale * (values.num + kit_.total_kits);
                let sumTotalKitFinal = hasWholesale || totalAmount >= 15001 || (totalAmount + sumTotalRetail > 15000) ? sumTotalKitFinalWholesale : sumTotalKitFinalRetail;
                let sumTotal = sumTotalKitFinal * (values.num + kit_.total_kits);
                let unitary_prirce = sumTotal / (values.num + kit_.total_kits);
                let discountSumTotal = sumTotal * 0.05;
                kit_.sub_sum_total_kit = sumTotalKitFinal.toFixed(2);
                kit_.sum_total_kit = sumTotalKitFinal.toFixed(2);
                kit_.sum_discount_kit = sumTotal - discountSumTotal;
                kit_.sum_total =  sumTotal.toFixed(2);
                kit_.discount_kit = discountSumTotal.toFixed(2);
                kit_.total_kits = (values.num + kit_.total_kits);
                kit_.items = newListOptionsColorKit;
                kit_.unitary_kit_price = unitary_prirce;
                kits[idx] = kit_;
            }
            dispatch(
                setKits({kits: kits})
            );
            toast.success("¡Se han agregado exitosamente los productos al kit!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setTimeout(() => {
                setLoading(false);
                onClose();
            }, 2000);
            
        } else {
            toast.error("¡Oops! Algo salió mal, vuelve a interntarlo nuevamente", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    const filterCodeSelectColor = (itemKit, color) => {
        let codeFilterData = "";
        let filterData = showKitIncludes[itemKit].items.filter(itemx => itemx.color === color);
        if (filterData.length > 0) {
            codeFilterData = filterData[0].code
        }
        return codeFilterData;
    }

    const filterSkuSelectColor = (itemKit, color) => {
        let skuFilterData = "";
        let filterData = showKitIncludes[itemKit].items.filter(itemx => itemx.color === color);
        if (filterData.length > 0) {
            skuFilterData = filterData[0].sku
        }
        return skuFilterData;
    }

    const filterObjShoppingCart = () => {
        const listColors = [valuesTypeColor.colorp1, valuesTypeColor.colorp2, valuesTypeColor.colorp3, valuesTypeColor.colorp4];
        const selectsOptionsColorKit = showKitIncludes.map((item, idx) => {
            return {
                ...item,
                color: listColors[idx],
                sku: filterSkuSelectColor(idx, listColors[idx]),
                code_item: filterCodeSelectColor(idx, listColors[idx]),
                code: filterCodeSelectColor(idx, listColors[idx]),
                printing: { type: "none", price:  0 }
            }
        })
        if (selectsOptionsColorKit.length > 0) {
            setShowKitIncludes(selectsOptionsColorKit);
            addKitShoppingCart(selectsOptionsColorKit);
        } 
    }

    const renderOptionColors = (product) => {
        const colors_ = [];
        product.items.forEach((elmnt) => {
            colors_.push({sku: elmnt.sku, color: elmnt.color})
        })
        return colors_;
    }

    useEffect(() => {
        const select1 = renderOptionColors(showKitIncludes[0]);
        setColors1(select1);
        const select2 = renderOptionColors(showKitIncludes[1]);
        setColors2(select2);
        const select3 = renderOptionColors(showKitIncludes[2]);
        setColors3(select3);
        const select4 = renderOptionColors(showKitIncludes[3]);
        setColors4(select4);
    }, [showKitIncludes]);

    const handleGetKitIdx = (newListOptionsColorKit) => {
        let indx = -1
        kitsStore.forEach((item, index) => {
            if (item?.sku_kit === kit?.sku) {
                let band = true
                item?.items.forEach((elmnt, idx) => {
                    if (elmnt?.sku === newListOptionsColorKit[idx]?.sku && band && elmnt?.color === newListOptionsColorKit[idx]?.color) {
                        band = true
                    }else{
                        band = false
                    }
                })
                if(band) {
                    indx = index
                }
            }
        })
        return indx
    }

    useEffect(() => {
        if(kitsStore.length > 0){
            calculateTotals();
        }
    },[kitsStore])

    useEffect(() => {
        handleChangePrices();
    },[hasWholesale])

    const handleChangePrices = () => {
        let products_ = [...productsStore]
        let kits_ = [...kitsStore]
        if(hasWholesale){
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.wholesale_price
                product_.total_price = product_?.wholesale_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_wholesale_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_wholesale_price
                kits_[idx] = kit_
            })
        }else{
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.retail_price
                product_.total_price = product_?.retail_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_retail_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_retail_price
                kits_[idx] = kit_
            })
        }
        dispatch(
            setProducts({products: [
                ...products_
            ]})
        )
        dispatch(
            setKits({kits: [
                ...kits_
            ]})
        )
    }


    const calculateTotals = () => {
        let subtotal_wholesale = 0
        let subtotal_retail = 0
        let iva_wholesale = 0
        let iva_retail = 0
        let shipping_wholesale = 199
        let shipping_retail = 199
        let total_printing = 0
        let total = 0
        let total_dicount_wholesale = 0
        let total_dicount_retail = 0

        productsStore.forEach(product_ => {
            subtotal_retail += parseFloat(product_.retail_price) * parseInt(product_.quantity)
            subtotal_wholesale += parseFloat(product_.wholesale_price) * parseInt(product_.quantity)
            total_printing += parseFloat(product_.printing.price) * parseInt(product_.quantity)
        })

        kitsStore.forEach(kit => {
            let total_kit_wholesale = 0;
            let total_kit_retail = 0;
            kit?.items?.forEach(item => {
                total_kit_wholesale += parseFloat(item?.wholesale_price) * parseInt(kit?.total_kits) 
                total_kit_retail += parseFloat(item?.retail_price) * parseInt(kit?.total_kits)
            })
            total_dicount_retail += total_kit_retail * 0.05
            total_dicount_wholesale += total_kit_wholesale * 0.05
            total_kit_retail = total_kit_retail - (total_kit_retail * 0.05)
            total_kit_wholesale = total_kit_wholesale - (total_kit_wholesale * 0.05)
            subtotal_retail += total_kit_retail
            subtotal_wholesale += total_kit_wholesale
        })

        subtotal_retail += total_printing
        subtotal_wholesale += total_printing
        iva_retail = subtotal_retail * 0.16
        iva_wholesale = subtotal_wholesale * 0.16
        let total_whitout_shipping_retail = subtotal_retail + iva_retail
        let total_whitout_shipping_wholesale = subtotal_wholesale + iva_wholesale
        
        if (total_whitout_shipping_retail <= 3000) {
            shipping_retail = 199;
        } else if (total_whitout_shipping_retail >= 3001 && total_whitout_shipping_retail <= 9999) {
            shipping_retail = 99;
        } else if (total_whitout_shipping_retail >= 10000) {
            shipping_retail = 0;
        }

        if (total_whitout_shipping_wholesale <= 3000) {
            shipping_wholesale = 199;
        } else if (total_whitout_shipping_wholesale >= 3001 && total_whitout_shipping_wholesale <= 9999) {
            shipping_wholesale = 99;
        } else if (total_whitout_shipping_wholesale >= 10000) {
            shipping_wholesale = 0;
        }

        if((total_whitout_shipping_retail + shipping_retail) > 15000){
            total = total_whitout_shipping_wholesale + shipping_wholesale
            const totalBreakdown ={
                subtotal: subtotal_wholesale,
                total_discount: 0,
                total_discount_kits: total_dicount_wholesale,
                total_iva: iva_wholesale,
                total_printing: total_printing,
                total_shipping: shipping_wholesale,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: true}))
        }else{
            total = total_whitout_shipping_retail + shipping_retail
            const totalBreakdown ={
                subtotal: subtotal_retail,
                total_discount: 0,
                total_discount_kits: total_dicount_retail,
                total_iva: iva_retail,
                total_printing: total_printing,
                total_shipping: shipping_retail,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: false}))
        }
        dispatch(setTotalAmountRetail({totalAmountRetail: (total_whitout_shipping_retail + shipping_retail)}))
        dispatch(setTotalAmountWholesale({totalAmountWholesale: (total_whitout_shipping_wholesale + shipping_wholesale)}))
        dispatch(setTotalAmount({totalAmount: total}))
    }


    return ( 
        <Modal isOpen={isOpen} onClose={onClose} size={isGreaterThanMd ? '2xl' : "xs"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign={isGreaterThanMd ? "start" : "center"}>Agregar al carrito</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex>
                        <Text lineHeight={1.2} textAlign={isGreaterThanMd ? "start" : "center"}>Selecciona un color para agregar al carrito de compra</Text>
                    </Flex>
                    <Flex mt={10}  w={"100%"} flexDirection={isGreaterThanMd ? "row" : "column"}>
                        <Flex w={isGreaterThanMd? "40%" : "100%"}>
                            <Text>{capitalizeFirstLetter(showKitIncludes[0]?.name)}</Text>
                        </Flex>
                        <Flex w={isGreaterThanMd ? "60%" : "100%"} mt={isGreaterThanMd ? 0 : 3}>
                            <Select name='colorp1' onChange={handleChangeSelected} placeholder='Color'>
                                {colors1 && colors1.map((clr, idx) => (
                                    <option key={idx} value={clr.color}>{capitalizeFirstLetter(clr.color)}</option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                    <Flex mt={5} w={"100%"} flexDirection={isGreaterThanMd ? "row" : "column"}>
                        <Flex w={isGreaterThanMd? "40%" : "100%"}>
                            <Text>{capitalizeFirstLetter(showKitIncludes[1]?.name)}</Text>
                        </Flex>
                        <Flex w={isGreaterThanMd ? "60%" : "100%"} mt={isGreaterThanMd ? 0 : 3}>
                            <Select name='colorp2' onChange={handleChangeSelected} placeholder='Color'>
                                {colors2 && colors2.map((clr2, idx2) => (
                                    <option key={idx2} value={clr2.color}>{capitalizeFirstLetter(clr2.color)}</option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                    <Flex mt={5} w={"100%"} flexDirection={isGreaterThanMd ? "row" : "column"}>
                        <Flex w={isGreaterThanMd? "40%" : "100%"}>
                            <Text>{capitalizeFirstLetter(showKitIncludes[2]?.name)}</Text>
                        </Flex>
                        <Flex w={isGreaterThanMd ? "60%" : "100%"} mt={isGreaterThanMd ? 0 : 3}>
                            <Select name='colorp3' onChange={handleChangeSelected} placeholder='Color'>
                                {colors3 && colors3.map((clr3, idx3) => (
                                    <option key={idx3} value={clr3.color}>{capitalizeFirstLetter(clr3.color)}</option>
                                ))}
                            </Select>
                        </Flex>
                    </Flex>
                    <Flex mt={5} mb={10} w={"100%"} flexDirection={isGreaterThanMd ? "row" : "column"}>
                        <Flex w={isGreaterThanMd? "40%" : "100%"}>
                            <Text>{capitalizeFirstLetter(showKitIncludes[3]?.name)}</Text>
                        </Flex>
                        <Flex w={isGreaterThanMd ? "60%" : "100%"} flexDirection={"column"} mt={isGreaterThanMd ? 0 : 3}>
                            <Flex>
                                <Select name='colorp4' onChange={handleChangeSelected} placeholder='Color'>
                                    {colors4 && colors4.map((clr4, idx4) => (
                                        <option key={idx4} value={clr4.color}>{capitalizeFirstLetter(clr4.color)}</option>
                                    ))}
                                </Select>
                            </Flex>
                            <Flex w={"100%"} justifyContent={"center"}>
                                <Button 
                                    _hover={{
                                        bg: "#063D5F"
                                    }}
                                    mt={8}
                                    w={isGreaterThanMd ? "176px" : "100%"} 
                                    fontSize={"14px"} 
                                    fontWeight={500}
                                    onClick={() => filterObjShoppingCart()}
                                    isDisabled={validateValues()}>
                                    {loading ?
                                        <Spinner /> : "Agregar al carrito"
                                    }
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
 
export default ModalSelectColor;