import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    kits: [],
    kitsList: [],
    totalAmount: 0,
    hasWholesale: false,
    totalAmountRetail: 0,
    totalAmountWholesale: 0,
    totalBreakdown: {
        subtotal: 0,
        total_discount: 0,
        total_iva: 0,
        total_printing: 0,
        total_shipping: 0,
        total_amount: 0,
    },
    discountDetails: {
        discount_code: '',
        discount_percentage: 0,
        discount_amount: 0,
    },
}

export const counterSlice = createSlice({
    name: 'counterSlice',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload.products;
        },
        setTotalAmount: (state, action) => {
            state.totalAmount = action.payload.totalAmount;
        },
        setTotalAmountRetail: (state, action) => {
            state.totalAmountRetail = action.payload.totalAmountRetail;
        },
        setTotalAmountWholesale: (state, action) => {
            state.totalAmountWholesale = action.payload.totalAmountWholesale;
        },
        setKits: (state, action) => {
            state.kits = action.payload.kits;
        },
        setTotalBreakdown: (state, action) => {
            state.totalBreakdown = action.payload.totalBreakdown;
        },
        setDiscountDetails: (state, action) => {
            state.discountDetails = action.payload.discountDetails;
        },
        setHasWholesale: (state, action) => {
            state.hasWholesale = action.payload.hasWholesale;
        }

    }
})

export const { setProducts } = counterSlice.actions;
export const selectProducts = (state) => state.counterState.products;
export const { setKits } = counterSlice.actions;
export const selectKits = (state) => state.counterState.kits;
export const { setTotalAmount } = counterSlice.actions;
export const selectTotalAmount = (state) => state.counterState.totalAmount;
export const { setTotalAmountRetail } = counterSlice.actions;
export const selectTotalAmountRetail = (state) => state.counterState.totalAmountRetail;
export const { setTotalAmountWholesale } = counterSlice.actions;
export const selectTotalAmountWholesale = (state) => state.counterState.totalAmountWholesale;
export const { setTotalBreakdown } = counterSlice.actions;
export const selectTotalBreakdown = (state) => state.counterState.totalBreakdown;
export const { setDiscountDetails } = counterSlice.actions;
export const selectDiscountDetails = (state) => state.counterState.discountDetails;
export const { setHasWholesale } = counterSlice.actions;
export const selectHasWholesale = (state) => state.counterState.hasWholesale;
export default counterSlice.reducer;