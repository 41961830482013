import React, { useEffect, useState } from 'react';
import { 
    Flex,
    Spinner
} from '@chakra-ui/react';
import { colors_dict } from '../../resource';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetProductQuery } from '../../hooks/enbaapi';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectHasWholesale,
    selectKits,
    selectProducts,
    setTotalAmountRetail,
    setTotalAmountWholesale,
    setKits,
    setProducts,
    setHasWholesale,
    setTotalAmount,
    setTotalBreakdown,
    selectTotalBreakdown
} from '../../hooks/slices/counterSlice';

import MiniatureMb from '../../components/ProductSelect/Mb/MiniatureMb';
import DescriptionMb from '../../components/ProductSelect/Mb/DescriptionMb';
import CharacteristicsMb from '../../components/ProductSelect/Mb/CharacteristicsMb';
import imgDefault from '../../assets/images/productsT/none-product-white.png';

const ProductMb = () => {
    const productsStore = useSelector(selectProducts);
    const kitsStore = useSelector(selectKits);
    const totalAmount = useSelector(setTotalAmountRetail);
    const hasWholesale = useSelector(selectHasWholesale);
    const params_url = useParams();
    const [images, setImages] = useState(null);
    const [colors, setColors] = useState([]);
    const [colorsProduct, setColorsProduct] = useState([]);
    const [changeFirst, setChangeFirst] = useState(true);
    const [idx, setIdx] = useState(0);
    const [img, setImg] = useState('');
    const [product, setProduct] = useState(null);
    const [printingType, setPrintingType] = useState('none');
    const [selectedColor, setSelectedColor] = useState('');
    const dispatch = useDispatch();
    const params = {
        sku: params_url.product
    }
    const {data, isLoading} = useGetProductQuery(params);
    const [itemSelected, setItemSelected] = useState(data?.items[0]? data?.items[0] : null);

    useEffect(() => {
        if(data){
            setProduct(data);
            //setImg(data?.images?.product_images[0] || data?.images?.vector_images[0]);
            const images_ = [];
            const colors_ = [];
            if(data?.images?.product_images[0])
                images_.push(data?.images?.product_images[0]);
            if(data?.images?.vector_images[0])
                images_.push(data?.images?.vector_images[0]);
            data.items.map((item)=>{
                images_.push(...item?.images?.images_item ? (Object.keys(item?.images?.images_item).length > 0 ? item?.images?.images_item : []) : [])
                colors_.push({sku: item.sku, color: item.color})
            })
            setItemSelected(data?.items[0])
            setColors(colors_);
            setImages(images_);
        }
    },[data])

    useEffect(() => {
        const colors_ar = []
        colors.map((item)=>{
            let color_ = ''
            colors_dict.filter((color)=>{
                if(item.color.includes(color.color)){
                    color_ = [{
                        sku: item.sku,
                        color: item.color,
                        hex: color.hex
                    }]
                }
            })
            if(!color_[0]){
                color_ = [{
                        sku: item.sku,
                        color: item.color,
                        hex: '#444444'
                }]
            }
            colors_ar.push(color_[0])
        });
        if (colors_ar.length > 0 && changeFirst) {
            const imgPreviewColor = colors_ar.slice(0, 1);
            const filterProductColors = product?.items.filter(item => item.color === imgPreviewColor[0].color);
            const imgUrl = filterProductColors[0].images?.images_item[0] ;
            setImg(imgUrl);
            setChangeFirst(false);
        }
        setColorsProduct(colors_ar);
    },[colors]);

    useEffect(() => {
        if (!img){
            if(images?.length > 0){
                setImg(images[0]);
                setIdx(0);
            }else{
                setImg(imgDefault);
                setIdx(0);
            }
            
        }
    },[img])

    const handleSubmitToCart = (quantity, price) => {
        //console.log({printingType, product, quantity, price})
        
        let product_selected = handleGetProduct() 
        const {product_, idx} = handleGetProductCartIdx(product_selected[0].sku);
        let product_item = {}
        if(idx === -1){
            let printing_unitary = 0;
            if(printingType !== 'none'){
                switch(printingType){
                    case 'laser': {
                        if (quantity <= 99) {
                        printing_unitary = 25;
                        } else {
                        printing_unitary = 15;
                        }
                        break;
                    }
                    case 'serigrafia': {
                        if (quantity <= 99) {
                        printing_unitary = 8;
                        } else if (quantity > 99 && quantity <= 149) {
                        printing_unitary = 5;
                        } else {
                        printing_unitary = 3;
                        }
                        break;
                    }
                    default: {
                        printing_unitary = 0;
                        break;
                    }
                }

            }
            product_item  = {
                sku: product_selected[0]?.sku,
                code_item: product_selected[0]?.code,
                product_sku: product_selected[0]?.product_sku,
                unit_price: price,
                retail_price: parseFloat(product_selected[0]?.retail_price).toFixed(2),
                wholesale_price: parseFloat(product_selected[0]?.wholesale_price).toFixed(2),
                total_price: parseFloat(product_selected[0]?.retail_price) * quantity,
                total_price_retail: parseFloat(product_selected[0]?.retail_price) * quantity,
                total_price_wholesale: parseFloat(product_selected[0]?.wholesale_price)* quantity,
                total_printing: printing_unitary * quantity,
                quantity: quantity,
                name: product_selected[0]?.name,
                category: product?.category,
                color: product_selected[0]?.color,
                image: img,
                productsPreview: product_selected,
                printing: { type: printingType, price:  printing_unitary }
            }   
        }else{
            product_item  = {
               ...product_[0] 
            }
            let quantity_total = product_item.quantity + quantity
            let printing_unitary = 0;
            if(printingType !== 'none'){
                switch(printingType){
                    case 'laser': {
                        if (quantity_total <= 99) {
                        printing_unitary = 25;
                        } else {
                        printing_unitary = 15;
                        }
                        break;
                    }
                    case 'serigrafia': {
                        if (quantity_total <= 99) {
                        printing_unitary = 8;
                        } else if (quantity_total > 99 && quantity_total <= 149) {
                        printing_unitary = 5;
                        } else {
                        printing_unitary = 3;
                        }
                        break;
                    }
                    default: {
                        printing_unitary = 0;
                        break;
                    }
                }

            }
            product_item.unit_price =  parseFloat(product_item?.retail_price) * quantity_total > 15001 ||  parseFloat(totalAmount) > 15001 || hasWholesale ? product_item?.wholesale_price : product_item?.retail_price
            product_item.total_price = parseFloat(parseFloat(product_item?.retail_price) * quantity_total > 15001 ||  parseFloat(totalAmount) > 15001 ? product_item?.wholesale_price : product_item?.retail_price).toFixed(2) * quantity_total
            product_item.total_price_retail = parseFloat(product_item?.retail_price).toFixed(2) * quantity_total
            product_item.total_price_wholesale = parseFloat(product_item?.wholesale_price).toFixed(2) * quantity_total
            product_item.quantity = quantity_total
            product_item.total_printing = printing_unitary * quantity_total
            product_item.printing = { type: printingType, price:  printing_unitary }
        }
        let products = [...productsStore];
        if(idx === -1){
            products = [...products, product_item];
        }else{
            products[idx] = product_item
        }
        dispatch(
            setProducts({products: [
                ...products
            ]})
        );
        toast.success("¡Se ha agregado correctamente el producto!", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    const handleGetProduct = () => {
        //console.log({selectedColor})
        let product_ = data?.items?.filter((prod) => {
            if(prod.color === selectedColor.toUpperCase()){
                return prod
            }
        });
        return product_;
    }

    const handleGetProductCartIdx = (sku) => {
        let idx = -1
        let product_ = productsStore?.filter((prod, indx) => {
            if(prod.color === selectedColor.toUpperCase() && prod.sku == sku && prod.printing.type === printingType){
                idx = indx
                return prod
            }
        });
        return {product_, idx};
    }

    useEffect(() => {
        if(productsStore.length > 0){
            calculateTotals();
        }
    },[productsStore])

    useEffect(() => {
        handleChangePrices();
    },[hasWholesale])

    const handleChangePrices = () => {
        let products_ = [...productsStore]
        let kits_ = [...kitsStore]
        if(hasWholesale){
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.wholesale_price
                product_.total_price = product_?.wholesale_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_wholesale_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_wholesale_price
                kits_[idx] = kit_
            })
        }else{
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.retail_price
                product_.total_price = product_?.retail_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_retail_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_retail_price
                kits_[idx] = kit_
            })
        }
        dispatch(
            setProducts({products: [
                ...products_
            ]})
        )
        dispatch(
            setKits({kits: [
                ...kits_
            ]})
        )
    }


    const calculateTotals = () => {
        let subtotal_wholesale = 0
        let subtotal_retail = 0
        let iva_wholesale = 0
        let iva_retail = 0
        let shipping_wholesale = 199
        let shipping_retail = 199
        let total_printing = 0
        let total = 0
        let total_dicount_wholesale = 0
        let total_dicount_retail = 0

        productsStore.forEach(product_ => {
            subtotal_retail += parseFloat(product_.retail_price) * parseInt(product_.quantity)
            subtotal_wholesale += parseFloat(product_.wholesale_price) * parseInt(product_.quantity)
            total_printing += parseFloat(product_.printing.price) * parseInt(product_.quantity)
        })


        kitsStore.forEach(kit => {
            let total_kit_wholesale = 0;
            let total_kit_retail = 0;
            kit?.items?.forEach(item => {
                total_kit_wholesale += parseFloat(item?.wholesale_price) * parseInt(kit?.total_kits) 
                total_kit_retail += parseFloat(item?.retail_price) * parseInt(kit?.total_kits)
            })
            total_dicount_retail += total_kit_retail * 0.05
            total_dicount_wholesale += total_kit_wholesale * 0.05
            total_kit_retail = total_kit_retail - (total_kit_retail * 0.05)
            total_kit_wholesale = total_kit_wholesale - (total_kit_wholesale * 0.05)
            subtotal_retail += total_kit_retail
            subtotal_wholesale += total_kit_wholesale
        })

        subtotal_retail += total_printing
        subtotal_wholesale += total_printing
        iva_retail = subtotal_retail * 0.16
        iva_wholesale = subtotal_wholesale * 0.16
        let total_whitout_shipping_retail = subtotal_retail + iva_retail
        let total_whitout_shipping_wholesale = subtotal_wholesale + iva_wholesale
        
        if (total_whitout_shipping_retail <= 3000) {
            shipping_retail = 199;
        } else if (total_whitout_shipping_retail >= 3001 && total_whitout_shipping_retail <= 9999) {
            shipping_retail = 99;
        } else if (total_whitout_shipping_retail >= 10000) {
            shipping_retail = 0;
        }

        if (total_whitout_shipping_wholesale <= 3000) {
            shipping_wholesale = 199;
        } else if (total_whitout_shipping_wholesale >= 3001 && total_whitout_shipping_wholesale <= 9999) {
            shipping_wholesale = 99;
        } else if (total_whitout_shipping_wholesale >= 10000) {
            shipping_wholesale = 0;
        }

        if((total_whitout_shipping_retail + shipping_retail) > 15000){
            total = total_whitout_shipping_wholesale + shipping_wholesale
            const totalBreakdown ={
                subtotal: subtotal_wholesale,
                total_discount: 0,
                total_discount_kits: total_dicount_wholesale,
                total_iva: iva_wholesale,
                total_printing: total_printing,
                total_shipping: shipping_wholesale,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: true}))
        }else{
            total = total_whitout_shipping_retail + shipping_retail
            const totalBreakdown ={
                subtotal: subtotal_retail,
                total_discount: 0,
                total_discount_kits: total_dicount_retail,
                total_iva: iva_retail,
                total_printing: total_printing,
                total_shipping: shipping_retail,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: false}))
        }
        dispatch(setTotalAmountRetail({totalAmountRetail: (total_whitout_shipping_retail + shipping_retail)}))
        dispatch(setTotalAmountWholesale({totalAmountWholesale: (total_whitout_shipping_wholesale + shipping_wholesale)}))
        dispatch(setTotalAmount({totalAmount: total}))
    }

    return (
        <>
            {
                (isLoading || !product) ? (
                    <Flex width={"100%"} justifyContent={"center"}>
                        <Spinner size='xl' color='#064A73'/>
                    </Flex>
                ):
                (
                    <Flex flexDirection={"column"} width={"100%"}>
                        {
                            product && (
                                <Flex mt={5} justifyContent={"center"} flexDirection={"column"}>
                                    <MiniatureMb data={product} images={images} setImg={setImg} setIdx={setIdx} idx={idx}/>
                                    <DescriptionMb 
                                        previewImage={img}
                                        setImg={setImg}
                                        images={images} 
                                        data={product} 
                                        colors={colors}
                                        colorsProduct={colorsProduct} 
                                        setSelectedColor={setSelectedColor}
                                        selectedColor={selectedColor}
                                        printingType={printingType}
                                        setPrintingType={setPrintingType}
                                        handleSubmitToCart={handleSubmitToCart}
                                        itemSelected={itemSelected}
                                        setItemSelected={setItemSelected}
                                    />
                                </Flex>
                            )
                        }
                        {
                            product && (
                                <CharacteristicsMb 
                                    data={product} 
                                    colorsProduct={colorsProduct}
                                    previewImage={img} 
                                    selectedColor={selectedColor}
                                    setSelectedColor={setSelectedColor}
                                    printingType={printingType}
                                    setPrintingType={setPrintingType}
                                    handleSubmitToCart={handleSubmitToCart}
                                    itemSelected={itemSelected}
                                />
                            )
                        }
                    </Flex>
                )
            }
        </>
    );
}

export default ProductMb;
