import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
    Flex,
    Text,
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    TabIndicator,
    Input,
    Switch,
    Button,
    Select,
    Radio, 
    RadioGroup,
    Stack
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { formatterValue, capitalizeFirstLetter } from '../../resource/validate';
import { 
    selectTotalAmountRetail
} from '../../hooks/slices/counterSlice';
import TablePrice from '../TablePrice';

import { toast } from 'react-toastify';

const Characteristics = ({ data, colorsProduct, previewImage, setPrintingType, printingType, handleSubmitToCart, setSelectedColor, selectedColor, itemSelected }) => {
    const totalAmount = useSelector(selectTotalAmountRetail);
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [total, setTotal] = useState(0);
    const [amount, setAmount] = useState(0);
    const [radioBtnValue, setRadioBtnValue] = useState(null);
    const [values, setValues] = useState({
        amount: null,
        unitPrice: null
    });

    useEffect(() => {
        if (data) {
            if(totalAmount >= 15001){
                let unitRetailPrice = data.items[0]?.wholesale_price ? data.items[0]?.wholesale_price : (data.items[0]?.retail_price ? data.items[0]?.retail_price : 0);
                setValues({
                    ...values,
                    unitPrice: parseFloat(unitRetailPrice).toFixed(2),
                });
            }else{
                let unitRetailPrice = data.items[0]?.retail_price ? data.items[0]?.retail_price : (data.items[0]?.wholesale_price ? data.items[0]?.wholesale_price : 0);
                setValues({
                    ...values,
                    unitPrice: parseFloat(unitRetailPrice).toFixed(2),
                });
            }
        }
    }, []);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: parseInt(e.target.value)
        })
        setAmount(e.target.value)
    }

    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    const validateData = () => {
        if (selectedColor !== "" && values.amount > 0 && values.unitPrice > 0 && values.amount <= itemSelected?.stock) {
            return false;
        }
        return true;
    }

    useEffect(()=>{
        calculateTotal()
    },[amount, printingType]) 

    const calculateTotal = () => {
        let printing_unitary = 0;
        if(printingType !== 'none'){
            switch(printingType){
                case 'laser': {
                    if (values.amount <= 99) {
                      printing_unitary = 25;
                    } else {
                      printing_unitary = 15;
                    }
                    break;
                }
                case 'serigrafia': {
                    if (values.amount <= 99) {
                      printing_unitary = 8;
                    } else if (values.amount > 99 && values.amount <= 149) {
                      printing_unitary = 5;
                    } else {
                      printing_unitary = 3;
                    }
                    break;
                }
                default: {
                    printing_unitary = 0;
                    break;
                }
            }

        }
        const printing_total = printing_unitary * values.amount;
        const totalRetail = (data.items[0]?.retail_price * values.amount) + printing_total;
        let total = values.unitPrice * values.amount;
        total += printing_total;
        let unitPrice = data.items[0]?.retail_price ? data.items[0]?.retail_price : (data.items[0]?.wholesale_price ? data.items[0]?.wholesale_price : 0);
        if(total >= 15001 || totalAmount >= 15001 || totalRetail >= 15001 || (totalRetail + totalAmount) >= 15001){
            unitPrice = data.items[0]?.wholesale_price;
        }else{
            unitPrice = data.items[0]?.retail_price;
        }
        total = (unitPrice * values.amount) + printing_total;
        setTotal(total);
        setValues({
            ...values,
            unitPrice: parseFloat(unitPrice).toFixed(2),
        })
    }
    
    const handleSubmit = () => {
        if (isSwitchOn) {
            if(radioBtnValue === null && isSwitchOn && filterTypePrint(data.printing.printing_technique) === ""){
                toast.error('Por favor, seleccione un tipo de impresión.',{
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                return;
            }else{
                if (values.amount >= 50) {
                    handleSubmitToCart(values.amount, values.unitPrice);
                } else {
                    toast.error("¡Para productos con personalización, el mínimo de compra debe ser de 50 piezas!", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            }
        } else {
            setPrintingType('none');
            handleSubmitToCart(values.amount, values.unitPrice);
        }
    }

    const filterTypePrint = (str) => {
        const listStr = str.split("/");
        const listStrMap = listStr.map((element) => {
            return element.replace(/\s/g, '');
        })
        if (listStrMap.includes('Láser') && listStrMap.includes('Serigrafía')) {
            return "";
        }else{
            if(listStrMap.includes('Láser') || listStrMap.includes('Serigrafía')){
                if (listStrMap.includes('Láser')) {
                    if(isSwitchOn){
                        setPrintingType('laser');
                    }
                    return 'Láser';
                } else if (listStrMap.includes('Serigrafía')) {
                    if(isSwitchOn){
                        setPrintingType('serigrafia');
                    }
                    return 'Serigrafía';
                }else{
                    setPrintingType('none');
                    return listStrMap[0];
                }
            } else{
                setPrintingType('none');
            }
        }
        return listStr[0];
    }

    useEffect(() => {
        if(isSwitchOn){
            if(radioBtnValue !== '' && filterTypePrint(data.printing.printing_technique) === ""){
                switch(radioBtnValue){
                    case '1':
                        setPrintingType('laser');
                        break;
                    case '2':
                        setPrintingType('serigrafia');
                        break;
                    default:
                        setPrintingType('none');
                }
            }else{
                const printing_technique = filterTypePrint(data.printing.printing_technique)
                switch(printing_technique){
                    case 'Láser':
                        setPrintingType('laser');
                        break;
                    case 'Serigrafía':
                        setPrintingType('serigrafia');
                        break;
                    default:
                        setPrintingType('none');
                }
            }
        }else{
            setPrintingType('none');
        }
    },[isSwitchOn, radioBtnValue])



    return ( 
        <Flex color={"#424242"} fontSize={"16px"} mt={10} flexDirection={"column"} pl={10} pr={20}>
            <Flex flexDirection={"column"}>
                <Text as={"b"} mb={4}>Descripción y características</Text>
                <Text lineHeight={1.2}>
                    {data.description}
                </Text>
            </Flex>
            <Flex mt={10}>
                <TablePrice pdt={data}/>
            </Flex>
            <Flex mt={10}>
                <Tabs position="relative" variant="unstyled" width={"100%"}>
                    <TabList>
                        <Tab fontWeight={500}>Cotizar</Tab>
                        <Tab fontWeight={500}>Información básica</Tab>
                        <Tab fontWeight={500}>Impresión</Tab>
                        <Tab fontWeight={500}>Empaque</Tab>
                    </TabList>
                    <TabIndicator
                        mt="-1.5px"
                        height="2px"
                        bg="accent.500"
                        borderRadius="5px"
                        />
                    <TabPanels>
                        <TabPanel>
                            <form 
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <Flex width={"100%"} flexDirection={"column"}>
                                    <Flex width={"100%"} alignItems={"center"} justifyContent={"end"}>
                                        <Text fontWeight={400}>Agregar impresión</Text>
                                        <Switch ml={3} size='lg' isChecked={isSwitchOn} onChange={handleSwitchChange} isDisabled={filterTypePrint(data.printing.printing_technique) !== "" ?  (filterTypePrint(data.printing.printing_technique) !== 'Láser' && filterTypePrint(data.printing.printing_technique) !== 'Serigrafía' ? true: false) : false}/>
                                    </Flex>
                                    <Flex mt={5} justifyContent={"end"}>
                                        <Select 
                                            fontSize={"14px"} mr={5}
                                            width={"366px"} height={"56px"}
                                            placeholder='Seleccionar color' 
                                            value={capitalizeFirstLetter(selectedColor)}
                                            onChange={e => setSelectedColor(e.target.value)}
                                            icon={<ChevronDownIcon />}
                                            isRequired
                                        >
                                                {
                                                    colorsProduct && (
                                                        colorsProduct.map((e, idx) => {
                                                            return (
                                                                <option key={idx} value={capitalizeFirstLetter(e.color)}>{capitalizeFirstLetter(e.color)}</option>
                                                            )
                                                        })
                                                    )
                                                }
                                        </Select>
                                        <Input name='amount' type='number' onChange={handleChange} value={values.amount} min={50} fontSize={"14px"} width={"366px"} height={"56px"} placeholder='Cantidad' />
                                        <Input name='unitPrice' type='number' onChange={handleChange} value={values.unitPrice} min={0} fontSize={"14px"} width={"366px"} height={"56px"} placeholder='Precio unitario' ml={5} disabled/>
                                    </Flex>
                                    <Flex mt={6} display={isSwitchOn ? "flex" : "none"} width={"100%"} justifyContent={"end"}>
                                        <Flex flexDirection={"column"}>
                                            <Text fontWeight={400}><Text as={"b"}>Tipo de impresión:</Text>
                                                {" "}{filterTypePrint(data.printing.printing_technique)}
                                            </Text>
                                            {filterTypePrint(data.printing.printing_technique) === "" ?
                                                <RadioGroup mt={5} onChange={setRadioBtnValue} value={radioBtnValue} isRequired>
                                                    <Stack direction='column'>
                                                        <Radio value='1'>Láser</Radio>
                                                        <Radio value='2'>Serigrafía</Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            : null}
                                        </Flex>
                                    </Flex>
                                    <Flex mt={5} width={"100%"} justifyContent={"end"}>
                                        <Flex flexDirection={"column"} textAlign={"end"}>
                                            <Text fontWeight={400}><Text as={"b"}>Total:</Text>{" "}{formatterValue(total ? total : 0)}</Text>
                                            <Flex mt={5}>
                                                <Button 
                                                    ml={5} w={"208px"} 
                                                    fontSize={"14px"} 
                                                    fontWeight={500}
                                                    _hover={{
                                                        bg: "#063D5F"
                                                    }}
                                                    type='submit'
                                                    isDisabled={validateData()}>Agregar al carrito</Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </form>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDirection={"column"}>
                                <Text mt={5}><Text as={"b"} mr={5}>Material</Text>{data.material}</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Medida</Text>{data.measurements}</Text>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDirection={"column"}>
                                <Text mt={5}><Text as={"b"} mr={5}>Aréa de impresión</Text>{data.printing.printing_area}</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Técnica de impresión</Text>{data.printing.printing_technique}</Text>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDirection={"column"}>
                                <Text mt={5}><Text as={"b"} mr={5}>Peso neto</Text>{data.package.net_weight} {data.package.weight_unit.toLowerCase()}</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Peso bruto</Text>{data.package.gross_weight} {data.package.weight_unit.toLowerCase()}</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Alto</Text>{data.package.height} cm</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Largo</Text>{data.package.length} cm</Text>
                                <Text mt={5}><Text as={"b"} mr={5}>Ancho</Text>{data.package.width} cm</Text>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </Flex>
    );
}
 
export default Characteristics;